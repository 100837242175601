import { FC, ReactNode } from "react";
import { match } from "ts-pattern";

import { PcDatabaseDetailLayout } from "~/components/layout/PcDatabaseDetailLayout";
import PcPageDetailLayout from "~/components/layout/PcPageDetailLayout";
import PcPageRootLayout from "~/components/layout/PcPageRootLayout";
import { SimplePcLayout } from "~/components/layout/SimplePcLayout";
import { SimpleSpLayout } from "~/components/layout/SimpleSpLayout";
import SpDetailPageLayout from "~/components/layout/SpDetailPageLayout";
import SpPageRootLayout from "~/components/layout/SpPageRootLayout";

type Props = {
  layoutType:
    | null
    | "SimplePcLayout"
    | "SimpleSpLayout"
    | "SpTp7Layout"
    | "PcTp7LeftAlignedLayout"
    | "PcPageRootLayout"
    | "SpPageRootLayout"
    | "PcPageDetailLayout";
};

const EmptyLayout: FC<{
  children: ReactNode;
}> = ({ children }) => {
  return <div role={"layout"}>{children}</div>;
};

// 雰囲気
// - PC
//   - PcPageRootLayout: 装飾なし FABあり
//   - PcPageDetailLayout: PcPageRootLayout + 詳細ページ用の余白 + 背景色
//   - SimplePcLayout: PcPageRootLayout + 余白
//   - PcDatabaseDetailLayout: PcPageDetailLayout + 左寄せ. データベース用
// - SP
//   - SpPageRootLayout: 装飾なし FABあり
//   - SpDetailPageLayout: SpPageRootLayout + 詳細ページ用の余白 + 背景色
// 選び方
//
const useLayout = (
  props: Props = { layoutType: null }
): FC<{
  children: ReactNode;
}> => {
  const { layoutType } = props;
  return match(layoutType)
    .with(null, () => EmptyLayout)
    .with("SimplePcLayout", () => SimplePcLayout)
    .with("PcPageRootLayout", () => PcPageRootLayout)
    .with("PcPageDetailLayout", () => PcPageDetailLayout)
    .with("PcTp7LeftAlignedLayout", () => PcDatabaseDetailLayout)
    .with("SimpleSpLayout", () => SimpleSpLayout)
    .with("SpPageRootLayout", () => SpPageRootLayout)
    .with("SpTp7Layout", () => SpDetailPageLayout)

    .exhaustive();
};

export default useLayout;
