/**
 * 詳細ページの上の段階のページのためのレイアウト
 * たとえば /database/:id からみた /database とか
 */
import { ReactNode } from "react";

import ServiceFooter from "~/components/common/ServiceFooter";
import FloatActionMenu from "~/components/layout/floating/FloatActionMenu";
import { Header } from "~/components/layout/header";
import { font } from "~/lib/client/design";

const SpPageRootLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div role={"layout"} className={font.className}>
      <Header />
      {children}
      <FloatActionMenu />
      <ServiceFooter />
    </div>
  );
};

export default SpPageRootLayout;
