import { css } from "@emotion/react";
import { Theme, useTheme } from "@mui/material";
import Link from "next/link";
import { FC, useState } from "react";
import Modal from "react-modal";

import { FussyLogo } from "~/components/svg/fussyLogo";
import { engFont, fixedHeight, fixedWidth, flexRow } from "~/lib/client/design";
import useUnReadCount from "~/lib/client/hooks/notification/useUnReadCount";
import useIsSpMode from "~/lib/client/hooks/useIsSpMode";
import { useAppContext } from "~/pages/_app";

Modal.setAppElement("#__next");

const MenuIcon: FC<{
  isOpenMenu: boolean;
  openMenu: () => void;
  closeMenu: () => void;
}> = ({ isOpenMenu, openMenu, closeMenu }) => {
  const { unReadCount } = useUnReadCount();
  const theme = useTheme();
  const hasUnRead = typeof unReadCount !== "undefined" && unReadCount > 0;

  return (
    <div css={iconContainerStyle}>
      {isOpenMenu ? (
        <div css={openedIconContainerStyle} role={"button"} onClick={closeMenu}>
          <MenuOpenedIcon />
        </div>
      ) : (
        <div css={closedIconContainerStyle} role={"button"} onClick={openMenu}>
          <MenuClosedIcon />
        </div>
      )}
      {hasUnRead ? <div css={hasUnReadCircleStyle(theme)} /> : null}
    </div>
  );
};

const iconContainerStyle = css`
  ${fixedHeight("2rem")}
  ${fixedWidth("2rem")}
  position: relative;
`;

const openedIconContainerStyle = css`
  border-radius: 4px;
  background: #eee;

  display: flex;
  justify-content: center;
  align-items: center;
  ${fixedWidth("2rem")}
  ${fixedHeight("2rem")}
`;

const MenuOpenedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
  >
    <line
      x1="1.35355"
      y1="0.646447"
      x2="13.3536"
      y2="12.6464"
      stroke="#CCCCCC"
    />
    <line
      x1="0.646447"
      y1="12.6464"
      x2="12.6464"
      y2="0.646447"
      stroke="#CCCCCC"
    />
  </svg>
);

const closedIconContainerStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${fixedWidth("2rem")}
  ${fixedHeight("2rem")}
  gap: 4px;
`;

const MenuClosedIcon = () => (
  <>
    {Array.from({ length: 3 }).map((_, i) => (
      <hr key={i} css={closedMenuIconLineStyle} />
    ))}
  </>
);

const closedMenuIconLineStyle = css`
  width: 100%;
  height: 2px;
  background: #ccc;
  margin: 0;
`;

const MenuCard: FC<{
  isOpen: boolean;
}> = ({ isOpen }) => {
  const isSpMode = useIsSpMode();

  if (typeof isSpMode === "undefined") return <div />;

  return (
    <Modal
      style={{
        overlay: {
          top: isSpMode ? "calc(85vh - 6.5rem)" : "calc(90vh - 6.5rem)",
          bottom: "auto",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "transparent",
        },
        content: {
          top: "0",
          left: "auto",
          right: "auto",
          borderRadius: "1rem",
          overflow: "hidden",
          width: "288px",
          height: "fit-content",
          paddingLeft: "0",
          paddingRight: "0",
        },
      }}
      isOpen={isOpen}
    >
      {/*@ts-ignore*/}
      <MenuCardContent />
    </Modal>
  );
};

const MenuCardContent: FC = () => {
  const theme = useTheme();
  const { unReadCount } = useUnReadCount();

  if (typeof unReadCount === "undefined") return <div />;

  return (
    <div css={menuCardContentRootStyle}>
      <div css={menuCardItemsContainer(theme)}>
        {[
          { href: "/me", text: "My Page" },
          {
            href: "/notification",
            text: "Notification",
          },
        ].map(({ href, text }) => (
          <MenuCardItem
            key={href}
            href={href}
            text={text}
            unReadCount={unReadCount}
          />
        ))}
      </div>
      <div css={menuCardItemsContainer(theme)}>
        {[
          { href: "https://fussy.gitbook.io", text: "User Guide" },
          {
            href: "/info",
            text: "News",
          },
          {
            href: "https://fussy-inc.com",
            text: "Official",
          },
        ].map(({ href, text }) => (
          <MenuCardItem
            key={href}
            href={href}
            text={text}
            unReadCount={unReadCount}
          />
        ))}
      </div>
    </div>
  );
};

type MenuCardItemProps = {
  href: string;
  text: string;
  unReadCount?: number;
};

const MenuCardItem = ({ href, text, unReadCount }: MenuCardItemProps) => {
  const theme = useTheme();
  const haveUnRead =
    href === "/notification" && unReadCount !== undefined && unReadCount > 0;
  return (
    <Link
      key={href}
      href={href}
      css={menuItemStyle(theme, haveUnRead)}
      className={engFont.className}
    >
      {text}
      {haveUnRead ? <div css={hasUnReadCircleStyle(theme)} /> : null}
    </Link>
  );
};

const menuCardContentRootStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
`;

const menuCardItemsContainer = (theme: Theme) => css`
  background: ${theme.palette.background.default};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const menuItemStyle = (theme: Theme, haveUnRead: boolean) => css`
  width: 100%;

  color: ${theme.palette.text.primary};
  text-decoration: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  padding: 0.2rem;
  font-size: 10pt;

  border-right: 1px solid ${theme.palette.divider};

  &:last-child {
    border-right: none;
  }

  ${haveUnRead && {
    position: "relative",
  }}
`;

const hasUnReadCircleStyle = (theme: Theme) => css`
  position: absolute;
  right: 5%;
  top: -15%;
  width: 0.5rem;
  height: 0.5rem;
  background: ${theme.palette.primary.main};
  border-radius: 50%;
`;

const ModalContent: FC = () => {
  const { currentUser } = useAppContext();
  const theme = useTheme();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const openMenu = () => setIsOpenMenu(true);
  const closeMenu = () => setIsOpenMenu(false);

  if (typeof currentUser === "undefined") return <div />;

  return (
    <div css={modalContentRootStyle(theme)}>
      <Link href="/" css={fussyLogoLinkContainerStyle}>
        <FussyLogo />
      </Link>
      <MenuIcon
        isOpenMenu={isOpenMenu}
        openMenu={openMenu}
        closeMenu={closeMenu}
      />
      {currentUser ? (
        <Link href={"/me"}>{currentUser.name}</Link>
      ) : (
        <div css={unLoginContainerStyle}>
          <Link href={"/account/login"}>ログイン</Link>
          <span>/</span>
          <Link href={"/account/signup"}>新規登録</Link>
        </div>
      )}
      <MenuCard isOpen={isOpenMenu} />
    </div>
  );
};

const modalContentRootStyle = (theme: Theme) => css`
  color: ${theme.palette.text.primary};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
`;

const fussyLogoLinkContainerStyle = css`
  ${flexRow};
  gap: 0.25rem;
  align-items: center;

  svg {
    width: 78.3px;
    height: 24px;
  }
`;

const unLoginContainerStyle = css`
  font-size: 11px;
`;

const FloatActionMenu = () => {
  const isSpMode = useIsSpMode();
  return (
    <Modal
      isOpen={true}
      onRequestClose={() => {}}
      style={{
        overlay: {
          top: isSpMode ? "85dvh" : "90vh",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "transparent",
          zIndex: 100,
        },
        content: {
          top: "0",
          left: "auto",
          right: "auto",
          borderRadius: "1rem",
          overflow: "hidden",
          width: "300px",
          height: "66px",
          boxShadow: "0px 2px 2px #ccc",
          padding: "1rem",
        },
      }}
    >
      <ModalContent />
    </Modal>
  );
};

export default FloatActionMenu;
