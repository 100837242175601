import { css } from "@emotion/react";
import { FC, ReactNode } from "react";

import ServiceFooter from "~/components/common/ServiceFooter";
import FloatActionMenu from "~/components/layout/floating/FloatActionMenu";
import { font } from "~/lib/client/design";

export const SimplePcLayout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div role={"layout"} css={rootStyle} className={font.className}>
      {children}
      <FloatActionMenu />
      <ServiceFooter />
    </div>
  );
};

const rootStyle = css`
  margin: 0 auto;
`;
