import { css } from "@emotion/react";
import { FC, ReactNode } from "react";

import ServiceFooter from "~/components/common/ServiceFooter";
import FloatActionMenu from "~/components/layout/floating/FloatActionMenu";
import { Header } from "~/components/layout/header";
import { font } from "~/lib/client/design";

const PcDetailPageLayout: FC<{
  children: ReactNode;
}> = ({ children }) => (
  <div role={"layout"} css={rootStyle} className={font.className}>
    <Header />
    <div css={containerStyle}>{children}</div>
    <FloatActionMenu />
    <ServiceFooter />
  </div>
);

const rootStyle = css`
  background: #ffffff;
  margin: 0 auto;
`;

const containerStyle = css`
  padding-top: 1rem;
`;

export default PcDetailPageLayout;
