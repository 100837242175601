import { css } from "@emotion/react";
import { FC, ReactNode } from "react";

import ServiceFooter from "~/components/common/ServiceFooter";
import FloatActionMenu from "~/components/layout/floating/FloatActionMenu";
import { font } from "~/lib/client/design";
import useVisibilityBasedOnFooter from "~/lib/client/hooks/useVisibilityBasedOnFooter";

export const PcDatabaseDetailLayout: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { isVisible, footerRef } = useVisibilityBasedOnFooter();

  return (
    <div role={"layout"} css={rootStyle} className={font.className}>
      {children}
      {isVisible && <FloatActionMenu />}
      <div
        css={css`
          display: block;
          width: 75rem;
          margin: 0 auto;
        `}
        ref={footerRef}
      >
        <ServiceFooter />
      </div>
    </div>
  );
};

const rootStyle = css`
  background: #ffffff;
`;
