import { useEffect, useState } from "react";

import { useGraphqlClient } from "~/lib/client/hooks/useGraphqlClient";

const useUnReadCount = () => {
  const [unReadCount, setUnreadCount] = useState(0);
  const client = useGraphqlClient();

  useEffect(() => {
    if (!client) return;

    (async () => {
      const { serviceNotifications } = await client.currentUserNotifications();
      const unreadCount = serviceNotifications.filter(
        (notification) => !notification.isRead
      ).length;
      setUnreadCount(unreadCount);
    })();
  }, [client]);

  return { unReadCount };
};

export default useUnReadCount;
