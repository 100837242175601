import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Theme, useTheme } from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/router";

import { FussyLogo } from "~/components/svg/fussyLogo";
import { fixedHeight, fixedWidth, flexRow } from "~/lib/client/design";
import useIsSpMode from "~/lib/client/hooks/useIsSpMode";

const ROOT_PAGES = [
  {
    path: "/",
    name: "Discovery",
  },
  {
    path: "/category",
    name: "Fave",
  },
  {
    path: "/category/[id]",
    name: "Fave",
    link: "/category",
  },
  {
    path: "/database",
    name: "Database",
  },
  {
    path: "/notification",
    name: "Notification",
  },
  {
    path: "/notification/[id]",
    name: "Notification",
    link: "/notification",
  },
  {
    path: "/info",
    name: "Information",
  },
  {
    path: "/info/[slug]",
    name: "Information",
    link: "/info",
  },
  {
    path: "/quest",
    name: "Quest",
  },
  {
    path: "/quest/[id]",
    name: "Quest",
    link: "/quest",
  },
  {
    path: "/me",
    name: "MyPage",
  },
  {
    path: "/ad/[id]",
    name: "　",
  },
  {
    path: "/raid",
    name: "Project",
  },
  {
    path: "/raid/[id]",
    name: "Project",
  },
  {
    path: "/category/[id]/membership",
    name: "Membership",
  },
  {
    path: "/category/[id]/membership/[membershipId]",
    name: "Membership",
  },
] as const;

const Header = () => {
  const isSpMode = useIsSpMode();
  const theme = useTheme();
  const router = useRouter();
  const pageTitle = ROOT_PAGES.find((p) => p.path === router.pathname);

  return (
    <header css={headerRootStyle(!!isSpMode, theme)}>
      <div css={headerTopStyle}>
        <Link css={logoContainerStyle(!!isSpMode)} href={"/"}>
          <FussyLogo />
        </Link>
        {pageTitle?.name && <H1 isSpMode={!!isSpMode}>{pageTitle.name}</H1>}
      </div>
    </header>
  );
};

const headerRootStyle = (isSpMode: boolean, theme: Theme) => css`
  padding: ${isSpMode ? "0.5rem 1rem 0.5rem 1rem" : "2rem 4rem 2rem 4rem"};
  background: ${theme.palette.background.paper};
`;

const headerTopStyle = css`
  ${flexRow};
  justify-content: space-between;
  align-items: center;
`;

const logoContainerStyle = (isSpMode: boolean) => css`
  ${fixedWidth(isSpMode ? "98px" : "156.57px")};
  ${fixedHeight(isSpMode ? "30px" : "50.3px")};

  svg {
    width: 100%;
    height: 100%;
  }
`;

const H1 = styled.h1<{ isSpMode: boolean }>`
  font-size: ${({ isSpMode }) => (isSpMode ? "30px" : "44px")};
`;

export { Header };
