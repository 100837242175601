import { FC, ReactNode } from "react";

import ServiceFooter from "~/components/common/ServiceFooter";
import FloatActionMenu from "~/components/layout/floating/FloatActionMenu";
import { Header } from "~/components/layout/header";
import { font } from "~/lib/client/design";

export const PcPageRootLayout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div role={"layout"} className={font.className}>
      <Header />
      {children}
      <FloatActionMenu />
      <ServiceFooter />
    </div>
  );
};

export default PcPageRootLayout;
