// useVisibilityBasedOnFooter.ts
import { useEffect, useRef, useState } from "react";

const useVisibilityBasedOnFooter = () => {
  const [isVisible, setIsVisible] = useState(true);
  const footerRef = useRef(null);

  useEffect(() => {
    const windowHeight = window.innerHeight;
    const fullContentHeight = document.documentElement.scrollHeight;
    const ref = footerRef.current;

    if (fullContentHeight <= windowHeight) {
      setIsVisible(true);
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (ref) {
      observer.observe(ref);
    }

    return () => {
      if (ref) {
        observer.unobserve(ref);
      }
    };
  }, []);

  return { isVisible, footerRef };
};

export default useVisibilityBasedOnFooter;
