import { css } from "@emotion/react";
import { Theme, useTheme } from "@mui/material";
import { FC, ReactNode } from "react";

import ServiceFooter from "~/components/common/ServiceFooter";
import FloatActionMenu from "~/components/layout/floating/FloatActionMenu";
import { Header } from "~/components/layout/header";
import { font } from "~/lib/client/design";

const SpDetailPageLayout: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const theme = useTheme();
  return (
    <div role={"layout"} css={rootStyle} className={font.className}>
      <div css={headerStyle(theme)}>
        <Header />
      </div>
      {children}
      <FloatActionMenu />
      <ServiceFooter />
    </div>
  );
};

const rootStyle = css`
  background: #ffffff;
`;

const headerStyle = (theme: Theme) => css`
  background: ${theme.palette.background.paper};
  padding: 0.5rem 0;
`;

export default SpDetailPageLayout;
